import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styles from './Viewer.module.css'
import { Button, Input, FormGroup, Label } from 'reactstrap'
import Dialog from './Dialog'
import { Link } from 'react-router-dom'
import axios from 'axios'
import gear from '../images/gear.svg'
import preview from '../images/preview.svg'
import Header from './Header'
import Footer from './Footer'
import Notification from './Notification'
import BackButton from './BackButton'
import Area from './Area'
import Spinner from './Spinner'
import defaultConfig from './defaultConfig.json'
import { Helmet } from 'react-helmet'
import { Dialog as InfoDialog, MenuItem, Select } from '@mui/material'
import { inflateLayout, LAYOUT_NAME } from '../helpers/layout'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AutoHyperlink } from './AutoHyperlink'
import { FETCH_VIEWER_SUCCESS, SET_LAYOUT_NAME } from '../redux/project'
import { getCgiUrl } from '../helpers/url'
import { getAndSetDefaultParameterValues } from './ViewerContextUtils'
import { AppVariables } from './AppVariables'
import { FontListButton } from "./FontList"
import "typeface-exo"

class Viewer extends Component {
  

  state = {
    popupInfoVisible: false,
    dialogVisible: false,
    notification: {},
    loading: true,
    showJSONEditor: false,
    config: null,
  }

  onJsonEditClick = () => {
    this.setState({ showJSONEditor: true })
  }

  getUrl(data) {
    //console.log("get URL ",data);
    try {
      let url = '/api/sparql/' + data.repoURL
      if (data.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = data.repoURL
      }
      var modus = false
      if (window.location.host === 'localhost:3000') {
        modus = true
      }
      //  var modus=false; //debug

      if (data.localRepoURL != null && data.localRepoURL !== '' && modus) {
        url = data.localRepoURL
      }
     
      return url
    } catch (e) {
      console.log('error creating url ', e)
    }
    return null
  }

  /**
   * @param bbsparqlendpoint {string}
   * @param repoUrl {string}
   * @return {string}
   */
  constructRepoLoginPath(bbsparqlendpoint, repoUrl) {
    const { viewerPath } = getViewProps(this.props)
    const repoLoginParams = new URLSearchParams(bbsparqlendpoint)
    repoLoginParams.set('repoUrl', repoUrl)
    repoLoginParams.set('viewerPath', viewerPath)

    return '/loginbb?id=' + encodeURIComponent(btoa('?' + repoLoginParams.toString()))
  }

  async componentDidMount() {
    const { projectPath, viewerPath } = getViewProps(this.props)

    let viewer = this.props.project.viewers.find((v) => v.path === viewerPath)
    if (viewer && viewer.loaded) {
        console.log("component did mount load into state");
      return this.loadViewerIntoState(viewer)
    }
    // no idea where CONFIG_URL comes from....
      if ((window.CONNECTED_APPS_STATIC) && (window.CONFIG_URL==null)) 
      {
        window.CONFIG_URL="./config.json";
      }
      
    const request = window.CONNECTED_APPS_STATIC
      ? {
          method: 'get',
          url: window.CONFIG_URL,
          headers: { 'Content-Type': 'application/json' },
        }
      : {
          method: 'get',
          url: `/api/projects/${projectPath}/viewers/${viewerPath}`,
          headers: { Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}` },
        }

    let config
    try {
     // console.log(request);

      const configResponse = await axios(request)
      config = configResponse.data
    } catch(error) {
      if (error.response?.status === 401) return this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)
      if (error.response?.status === 404) return this.loadNewViewerConfig(viewerPath)
      throw error
    }
    if (!config) throw new Error('somehow no config was fetched')

    if (window.CONNECTED_APPS_STATIC == null && config.repoURL.startsWith('http'))
    {
      if (config.repoURL.includes("BloodBee"))
      {
            // (Hans: so annoying so skipping this when it contains BloodBee
      }
      else
      {
        alert(`Attention: This app interacts with external triplestore-endpoint "${config.repoURL}". Ignore this message if this is on purpose. This message will not show up when this app is locked-and-published.`)
      }
      
    }


    if (config.prejs != null) {
      try {
        eval(config.prejs) // eslint-disable-line no-eval
      } catch (error2) {
        console.log(error2, config.prejs)
      }
    }

    const loadConfig = async () => {
      console.log("load config load into state");
      await this.loadViewerIntoState(config)
      this.props.dispatch({ type: FETCH_VIEWER_SUCCESS, data: config })
      this.keepAlive('init')
    }

    if (!this.shouldLogin(config)) return loadConfig()

    const auth = await this.reauth(config)
    if (auth.isAutoLogin && !auth.success) return auth.goToLoginPage()

    const isAuthorised = await this.isAuthorised(config)
  
    const user = isAuthorised.user
    if (!user) throw new Error('somehow no user data was fetched')
    if (!user)  return isAuthorised.goToLoginPage()
        this.myPublish('user.fullAccess', Boolean(user.fullAccess))
    if (!isAuthorised.success) return isAuthorised.goToLoginPage()

    if (user.parameters!=null) document.userParameters=user.parameters;
    if (document.userParameters==null) document.userParameters={};
    document.userParameters.userUri=user.userUri;
    document.userParameters.userName=user.userName;
    loadConfig()
  }

  /** @param {'init' | 'destroy'} mode */
  keepAlive = (mode) => {
    if (mode === 'init') {
      if (!this.keepAliveInterval) setInterval(() => this.isAuthorised(), 5*60*1000)
      return
    }

    if (mode === 'destroy') {
      if (!this.keepAliveInterval) return
      clearInterval(this.keepAliveInterval)
      return this.keepAliveInterval = null
    }


    throw new Error(`unknown mode: ${JSON.stringify(mode)}`)
  }

  /**
   * @param {any} config
   * @returns {Promise<{ user: any, success: boolean, goToLoginPage: () => any }>}
   */
  async isAuthorised(config = this.state.viewer) {
    const {isLoggedInUrl, viewerPath, goToLoginPage, repo, repo2} = this.viewerParams(config)
    let user
    try {
      const userResponse = await axios({
        method: 'get',
        url: isLoggedInUrl,
        withCredentials: true,
        params: { viewerPath }
      })
      user = userResponse.data
    } catch(error) {
      console.error(error)
    }

    const success = (() => {
      if (!user) {
        console.error('no user data was fetched')
        return false
      }

      if (user.loggedIn.toLowerCase() !== 'true') return false

      if (user.oidcLoginStatus === 'USER_NOT_PERMITTED' || user.oidcLoginStatus === 'REPO_NOT_PERMITTED') {
        alert('Your account does not have sufficient permissions for this application.')
        return false
      }

      if (repo == null || user.repo == null) return false

      if (repo.toLowerCase() !== user.repo.toLowerCase() && repo2.toLowerCase() !== user.repo.toLowerCase()) {
        alert('wrong repo. This account is not valid for this application.')
        return false
      }

      return true
    })()

    return {user, goToLoginPage, success}
  }

  /**
   * @param {any} config
   * @returns {Promise<{ isAutoLogin: boolean, success: boolean, goToLoginPage: () => any }>}
   */
  async reauth (config = this.state.viewer) {
    if (config == null) throw new Error('Reauth requested without any config loaded')

    const { isAutoLogin, psw, login, autoLoginUrl, goToLoginPage } = this.viewerParams(config)
    if (!isAutoLogin) return { isAutoLogin, goToLoginPage, success: false }

    let success
    try {
      this.autoLogin = this.autoLogin ?? axios({
        method: 'post',
        url: autoLoginUrl,
        withCredentials: true,
        data: { login, psw },
      })
      const response = await this.autoLogin
      success = response.data?.loggedIn?.toLowerCase?.() === 'true'

    } catch(error) {
      success = !(error?.response?.status === 401 || error?.response?.status === 403)
      if (!success) alert('Default login credentials are incorrect. Login with valid credentials and change it to fix this.')
    }

    this.autoLogin = null
    return { isAutoLogin, goToLoginPage, success }
  }

  viewerParams(config = this.state.viewer) {
    const { projectPath, viewerPath } = getViewProps(this.props)

    const params = new URLSearchParams(config.bbsparqlendpoint)
    const repo = params.get('repo')
    const repo2 = params.get('repo2') ?? ''
    const psw = params.get('psw')
    const login = params.get('login')
    const isAutoLogin = psw != null && login != null

    const sparqlUrl = getCgiUrl('sparql', config.repoURL, config.localRepoURL)
    const autoLoginUrl = getCgiUrl('login', config.repoURL, config.localRepoURL)
    const isLoggedInUrl = getCgiUrl('isloggedin', config.repoURL, config.localRepoURL)
    const repoLoginUrl = this.constructRepoLoginPath(config.bbsparqlendpoint, sparqlUrl)

    const goToLoginPage = () => this.props.history.replace(repoLoginUrl, this.props.location.pathname)

    return {projectPath, viewerPath, repo, repo2, isAutoLogin, psw, login, autoLoginUrl, sparqlUrl, isLoggedInUrl,
            repoLoginUrl, goToLoginPage}
  }

  shouldLogin(config) {
    const isConnectedToLocalRepo = config.localRepoURL?.includes(':7200') && window.location.host === 'localhost:3000'
    if (isConnectedToLocalRepo) return false
    if (config.deleted) return false
    if (window.location.search.includes("debug=skiplogin")) return false
    if (this.props.loginState?.isLoggedIn) return false
    return Boolean(config.bbsparqlendpoint?.length)
  }

  loadNewViewerConfig(viewerPath) {
    this.setState({
      viewer: inflateLayout({ ...defaultConfig, path: viewerPath, new: true }),
      loading: false,
    })
  }

  componentDidUpdate() {
    const viewer = this?.state?.viewer
    if (viewer == null) return

    if (this.state.viewer.update60Enabled) {
      // console.log("we shoud update 60 ",this.state);
      if (this.state.update60Running) {
        // console.log("but is already running");
      } else {
      //   console.log("starting update 60 ");
      var me =this;
         setTimeout(function () {me.changingProps()},500 );  // why is this suddenly a problem?
       // ()
      }
    } else {
     //  console.log("update 60 should not be running")
      if ( (true) && (this.state.update60Running) ) {

        this.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60Running: false,
          },
        })
        //  console.log("disabling update 60")
      } else {
        // console.log("update60 is not running");
      }
    }

  }

   myPublish(par,value)
  {
   //  console.log("dispatching ",par,value);
   var data ={};
   data[par]=value;
   this.props.dispatch({
     type: 'PUBLISH',
     data: data
   })
//   console.log("dispatching end ",par,value);
  }
 

  loadViewerIntoState(viewer) {
    if (this.loadViewerIntoStateOnlyOnce!=null) return;
    this.loadViewerIntoStateOnlyOnce=true;
   // console.log("Load Viewer Into State ",viewer);
    return new Promise((resolve, reject) => {
      try {
        this.setState({
          viewer: inflateLayout(viewer),
          loading: false,
          popupInfoVisible: viewer.popupInfoAtStart,
        }, resolve)
        this.props.dispatch({ type: SET_LAYOUT_NAME, data: viewer.layoutName })

        var r = getAndSetDefaultParameterValues(viewer);
        for (var key in r) {
          console.log("setting default parameters ",key,r[key])
          this.myPublish(key,r[key]);
        }
      } catch(e) {
        reject(e)
      }
    })
  }


  updateViewer(partialViewer) {
    this.setState({ viewer: { ...this.state.viewer, ...partialViewer } })
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  }
  changingProps() {
    if (this.state.update60Running) return

    // console.log("setting first update60 value");

    this.props.dispatch({
      type: 'PUBLISH',
      data: {
        update60: 'start',
        update60Running: true,
      },
    })

    this.changingProps60(true)
  }

  changingProps60(b) {
    if (b || this.state.viewer.update60Enabled) {
      var me = this
      if (me.loop60) return
      me.loop60 = true
      setTimeout(function () {
        // console.log("changing update60 value");
        let id = me.uuidv4()
        me.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60: id,
          },
        })
       // console.log('changing 60')
        me.loop60 = false
        me.changingProps60(false)
      }, 60000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.keepAlive('destroy')
  }

  onChange = (config) => {
    let viewer = { ...this.state.viewer, mainArea: config }
    this.setState({ viewer })
  }

  selectLayout = (layoutName) => {
    let viewer = inflateLayout({ ...this.state.viewer, layoutName })
    this.setState({ viewer })
    this.props.dispatch({ type: SET_LAYOUT_NAME, data: layoutName })
  }

  save = () => {
    let viewer = this.state.viewer
    delete viewer.project

    const { projectPath, viewerPath } = getViewProps(this.props)

    let url = `/api/projects/${projectPath}/viewers` + (viewer.new ? '' : `/${viewerPath}`)
    axios({
      method: viewer.new ? 'post' : 'put',
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
      data: viewer,
    })
      .then(({ data }) => {
        this.setState({ notification: { text: 'saved', type: 'success' } })
        this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)

        let viewer = inflateLayout({ ...data, new: false })
        this.setState({ viewer })
        this.props.dispatch({ type: 'SAVE_VIEWER_SUCCESS', data })
        let s = data
        delete s.id
        delete s.path
        delete s.project
        delete s.user
        delete s.createdAt
        // console.log(JSON.stringify(s));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)
        } else {
          this.setState({
            notification: {
              text: 'not saved: ' + error.response.data,
              type: 'failure',
            },
          })
          this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)
        }
      })
  }

  onDialogOkClick = () => {
    this.setState({ dialogVisible: false })
    this.save()
  }

  onHeaderClick = (e) => {
    if (getViewProps(this.props).mode === 'edit') {
      this.setState({ dialogVisible: true })
    }
  }

  jsonOKClick = () => {
    this.setState({ showJSONEditor: false })
  }

  render() {
    // console.log("viewer render function",this);
    if (this == null) return <Spinner />
    //  if (true){ var id="halalo"; this.props.history.push('/loginbb?id='+id, this.props.location.pathname); return null;}
    if (this.state == null) return <Spinner />
    if (this.state.loading) return <Spinner />
    /** @type {LoginState} */
    const loginState = this.props.loginState

    let viewer = this.state.viewer
    if (!viewer || viewer.deleted) return (
      <p>This viewer does not exist or has been deleted. Please contact an admin if this is a problem.</p>
    )

    var me = this
    document.st = function () {
      viewer.notShowTop = false
      me.setState({ notShowTop: false })
    }
    document.preview = function () {
      getViewProps(this.props).mode = 'preview'
      me.setState({ mode: 'preview' })
    }
    document.edit = function () {
      getViewProps(this.props).mode = 'edit'
      me.setState({ mode: 'edit' })
    }

    let notification = this.state.notification
    const { mode, projectPath, viewerPath } = getViewProps(this.props)

    let showBottom = viewer.notShowBottom
    if (showBottom == null) {
      showBottom = true
    } else {
      showBottom = !showBottom
    }

    let showTop = viewer.notShowTop
    if (showTop == null) {
      showTop = true
    } else {
      showTop = !showTop
    }

  

    let css = viewer.css
    if (css) {
      try {
        css = JSON.parse(css)
      } catch (error) {
        css = {}
      }
    } else {
      css = {}
    }

   
    /*if (this.state.showJSONEditor)
    {
              return (<JsonEditor></JsonEditor>)
    }
    else
    */
        
    return (
      <div className={styles.wrapper} style={css}>
        <div className={styles.mainContainer}>

          <Helmet>
            <title>{viewer.title}</title>
            {viewer.project && (
              <link
                rel="icon"
                type="image/png"
                href={`${window.CONNECTED_APPS_STATIC ? '.' : ''}/favicon-${viewer.project.path}.png`}
                sizes="16x16"
              />
            )}
          </Helmet>
          <Notification messageType={notification.type} text={notification.text} />

          {!showTop && loginState.isLoggedIn && (
            <>
              {mode === 'edit' && (
                <img
                  onClick={this.onHeaderClick}
                  src={gear}
                  alt="gear"
                  style={{ position: 'absolute', cursor: 'pointer' }}
                />
              )}
              <Link
                className={styles.configButton}
                to={`/projects/${projectPath}/${viewerPath}${mode === 'edit' ? '' : '/edit'}${window.location.search}`}
                style={{ textDecoration: 'none' }}
              >
                <img src={gear} alt="gear" style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} />
              </Link>
              {(new URLSearchParams(window.location.search)).get('hover') === 'app-variables' && (
                <AppVariables />
              )}
            </>
          )}

          {showTop && (
            <Fragment>
              {!window.CONNECTED_APPS_STATIC && <BackButton to={`/projects/${projectPath}`} />}
              {loginState.isLoggedIn && loginState.user.role !== 'viewer' && (
                <div className={styles.editButton}>
                  {mode === 'edit' ? (
                    <Link
                      to={`/projects/${projectPath}/${viewerPath}${window.location.search}`}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <img src={preview} alt="preview" style={{ width: '22px' }} />
                    </Link>
                  ) : (
                    <Link to={`/projects/${projectPath}/${viewerPath}/edit${window.location.search}`} style={{ textDecoration: 'none' }}>
                      <img src={gear} alt="gear" style={{ width: '19px', opacity: '0.5' }} />
                    </Link>
                  )}
                </div>
              )}

              <Header
                title={viewer.title}
                subtitle={viewer.subtitle}
                repoURL={viewer.repoURL}
                localRepoURL={viewer.localRepoURL}
                bbsparqlendpoint={viewer.bbsparqlendpoint}
                pageWidth={viewer.pageWidth}
                parseUrlProperties={viewer.parseUrlProperties}
                logoutbutton={viewer.logoutbutton}
                noLoginButton
                logoURL={viewer.logoURL}
                onInfoClick={viewer.infoMessage ? () => this.setState({ popupInfoVisible: true }) : null}
                onSettingsClick={mode === 'edit' ? this.onHeaderClick : null}
              />
            </Fragment>
          )}

          <div className={styles.main} style={{ maxWidth: viewer.pageWidth }}>
            <div className={styles.areaContainer}>
              <Area
                reauth={this.reauth.bind(this, viewer)}
                config={viewer.mainArea}
                onChange={this.onChange}
                mode={mode}
                repoURL={viewer.repoURL}
                localRepoURL={viewer.localRepoURL}
                parseUrlProperties={viewer.parseUrlProperties}
                sparqlErrorMessage={viewer.sparqlErrorMessage}
                parameterValues={viewer.parameterValues}
                save={this.save}
              />
            </div>
          </div>

          {this.state.viewer.layout?.showFooter && (
            <Footer onClick={mode === 'edit' ? this.onHeaderClick : null} config={viewer.footer} />
          )}

          <InfoDialog
            style={{ zIndex: 1337 }}
            maxWidth="xl"
            open={Boolean(this.state.popupInfoVisible && viewer.infoMessage)}
            onClose={() => this.setState({ popupInfoVisible: false })}
          >
            <div className={styles.infoDialogueContainer}>
              <AutoHyperlink text={viewer.infoMessage} />
            </div>
          </InfoDialog>

          {this.state.dialogVisible && (
            <Dialog>
              <FormGroup>
                <Label>Layout</Label>
                <Select
                  className="dark-form-control form-control white-font-override"
                  onChange={(e) => this.selectLayout(e.target.value)}
                  value={this.state.viewer.layoutName || ''}
                >
                  <MenuItem value={LAYOUT_NAME.CUSTOM}>Custom</MenuItem>
                  <MenuItem value={LAYOUT_NAME.ORIGINAL}>Original</MenuItem>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Title</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.title || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ title: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Subtitle</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.subtitle || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ subtitle: e.target.value })}
                />
              </FormGroup>

              <FormGroup>
                <Label>Info message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.infoMessage || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, infoMessage: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.popupInfoAtStart ?? false}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          popupInfoAtStart: e.target.checked,
                        },
                      })
                    }
                  />
                  show popup info at start
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>pre app javascript</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.prejs || ''}
                  type="javascript"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, prejs: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>bb repo and app parameters</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.bbsparqlendpoint || ''}
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, bbsparqlendpoint: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Sparql endpoint</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.repoURL || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, repoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>local sparql endpoint (http://localhost:7200/repositories/test)</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.localRepoURL}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, localRepoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>sparql endpoint error message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.sparqlErrorMessage || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: {
                        ...viewer,
                        sparqlErrorMessage: e.target.value,
                      },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>Logo URL</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.logoURL || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, logoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ display: 'flex', gap: '1rem' }}>
                  <div style={{ flexGrow: 1 }}>
                    Global CSS in JSON formaat, bijvoorbeeld: {'{"fontFamily": "Exo"}'}
                  </div>
                  <FontListButton />
                </Label>
                <Input
                  className="dark-form-control"
                  value={viewer.css || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, css: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Pagina breedte</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.pageWidth || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, pageWidth: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>default parameter values </Label>
                <Input
                  className="dark-form-control"
                  value={viewer.parameterValues || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, parameterValues: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.update60Enabled}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          update60Enabled: e.target.checked,
                        },
                      })
                    }
                  />
                  enable update60 parameter refresh at 60s interval
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.parseUrlProperties}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          parseUrlProperties: e.target.checked,
                        },
                      })
                    }
                  />
                  parse url parameters as property values
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowTop}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, notShowTop: e.target.checked },
                      })
                    }
                  />{' '}
                  do not show top
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.logoutbutton}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          logoutbutton: e.target.checked,
                        },
                      })
                    }
                  />
                  show logout button in the header
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowBottom}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          notShowBottom: e.target.checked,
                        },
                      })
                    }
                  />
                  do not show bottom
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>Config in JSON formaat</Label>
                <Input
                  className="dark-form-control"
                  value={JSON.stringify(viewer) || ''}
                  type="text"
                  onChange={(e) => {
                    let config = JSON.parse(e.target.value)

                    if (config != null) {
                      var old = this.state.viewer
                      config.id = old.id
                      config.user = old.user
                      config.path = old.path
                      this.setState({ viewer: config })
                    }
                  }}
                />
                <Button color="primary" style={{ minWidth: '120px', marginTop: '12px' }} onClick={this.onJsonEditClick}>
                  edit
                </Button>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.public}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, public: e.target.checked },
                      })
                    }
                  />
                  publiek
                </Label>
              </FormGroup>
              <div style={{ textAlign: 'center' }}>
                <Button color="primary" style={{ minWidth: '120px', marginTop: '12px' }} onClick={this.onDialogOkClick}>
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
  }
}

function getViewProps(props) {
  if (window.CONNECTED_APPS_STATIC)
    return {
      mode: 'preview',
      projectPath: window.PROJECT,
      viewerPath: window.VIEWER,
    }

  return {
    mode: props.match.params.mode,
    projectPath: props.match.params.projectPath,
    viewerPath: props.match.params.viewerPath,
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  loginState: state.login
})

export default connect(mapStateToProps)(Viewer)
